
/* state interface */
export interface AppState {
    initialized: boolean;
    loading: boolean;
    selectedCompanyId: string,
    division: any,
}

/* initial values */
export const initialState: AppState = {
    initialized: false,
    loading: null,
    selectedCompanyId: null,
    division: null
};
