import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppDataStoreModule } from '@app/shared/data-stores/app/app-data-store-module';
import { AssetsDataStoreModule } from './assets/assets-data-store.module';

/* data stores */
// import necessary data stores here

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AppDataStoreModule,
    AssetsDataStoreModule
  ],
  exports: [
    StoreModule,
    EffectsModule
  ]
})
export class DataStoresModule { }
