/* angular */
import { createReducer, on } from '@ngrx/store';
import { initialState } from './app-data-store-state';

/* services */

/* actions */
import * as AppDataStoreActions from './app-data-store.actions';


/* state key */
export const featureKey = 'app';

export const reducer = createReducer(
  initialState,
  /* reducer functions for actions */
  on(AppDataStoreActions.initApp, (state, action) => ({
    ...state,
    initialized: true
  })),

  on(AppDataStoreActions.setSelectedCompanyId, (state, action) => ({
    ...state,
    selectedCompanyId: action.payload.selectedCompanyId
  })),

  on(AppDataStoreActions.getDivisionByIdSuccessfully, (state, action) => ({
    ...state,
    division: action.payload.division
  })),

  on(AppDataStoreActions.getDivisionByIdFailure, (state, action) => ({
    ...state,
    error: action.payload.error
  })),

);
