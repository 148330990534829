import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ZonarUIMaterialModule } from '@zonar-ui/material';
import { Translations } from '@app/services/i18n/translations.service';
import { I18nService, TranslateService } from '@zonar-ui/i18n';
import { ActivatedRoute } from '@angular/router';
import { HomePageMode } from '@src/app/consts/app.const';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  imports: [CommonModule, ZonarUIMaterialModule],
})
export class HomePageComponent implements OnInit, OnDestroy {
  
  translationsNotLoaded = !!this.translateService.store.translations;
  pageMode = HomePageMode.NO_PERMISSION;
  pageTitle: string = '';
  pageMessage: string = '';
  homePageTranslatePath: string = 'homePage';
  private onDestroy$ = new Subject<void>();

  constructor(
    private i18nService: I18nService,
    public translateService: TranslateService,
    public translations: Translations,
    private route: ActivatedRoute
  ) {
    if (this.translationsNotLoaded) {
      this.translateService
        .use(this.i18nService.getLanguageInUse()).pipe(takeUntil(this.onDestroy$)).subscribe(() => this.translationsNotLoaded = false);
    }
  }
  
  ngOnInit(): void {
    if (this.route.snapshot?.data?.mode) {
      this.pageMode = this.route.snapshot.data.mode;
    }
    this.initPageContent();
  }

  initPageContent(): void {
    this.translateService.get(this.homePageTranslatePath).pipe(takeUntil(this.onDestroy$)).subscribe(translated => {
      switch (this.pageMode) {
        case HomePageMode.ASSET_NOT_FOUND:
          this.pageTitle = translated.assetNotFound.title;
          this.pageMessage = translated.assetNotFound.message;
          break;
        case HomePageMode.ASSET_PROFILE_BLANK:
          this.pageTitle = translated.assetBlank.title;
          this.pageMessage = translated.assetBlank.message;
          break;
        case HomePageMode.PAGE_NOT_FOUND:
          this.pageTitle = translated.pageNotFound.title;
          this.pageMessage = translated.pageNotFound.message;
          break;
        default:
            this.pageTitle = translated.noPermission.title;
            this.pageMessage = translated.noPermission.message;
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
