import { Injectable } from '@angular/core';
import { EntityApiBaseService } from './base-core-api.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoreDivisionApiService extends EntityApiBaseService {

  private DIVISION_RESOURCE = 'divisions';

  getDivisionById(divisionId: string) {
    const url = `${environment.coreEntityApiBase.url}/${this.DIVISION_RESOURCE}/${divisionId}`;
    return this.getData(url);
  }
}
