import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssetsDataStore, featureKey } from './assets-data-store.reducer';

export const selectAssetState = createFeatureSelector<AssetsDataStore>(featureKey);

export const selectAsset = createSelector(selectAssetState, state => {
    return state?.asset;
});

export const selectError = createSelector(selectAssetState, state => {
    return state?.error;
});