<div class="flex flex-row justify-center w-full">
  <mat-card class="page-container">
    <div class="page-content flex-col">
      <mat-card-title>
        <mat-icon>error_outline</mat-icon>
      </mat-card-title>

      <mat-card-content class="card-content flex flex-col">
        <span class="title" [innerHTML]="pageTitle"></span>
        <span class="message" [innerHTML]="pageMessage"></span>
      </mat-card-content>
    </div>
  </mat-card>
</div>
