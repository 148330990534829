/* angular */
import { createReducer, on } from '@ngrx/store';

/* actions */
import * as AssetsDataStoreActions from './assets-data-store.actions';
import { IAsset } from '@src/app/models/assets.model';

/* state key */
export const featureKey = 'assets';

/* state interface */

export interface AssetsDataStore {
  isLoading?: boolean;
  asset: IAsset,
  error: any
}


/* initial state */
export const initialState: AssetsDataStore = {
  isLoading: null,
  asset: null,
  error: null
};

export const reducer = createReducer(
  initialState,

  on(AssetsDataStoreActions.getAssetByIdSuccessfully, (state, action) => ({
    ...state,
    asset: action.payload.asset
  })),

  on(AssetsDataStoreActions.getAssetByIdFailure, (state, action) => ({
    ...state,
    error: action.payload.error
  })),
);


