import { Injectable } from "@angular/core";
import { environment } from '@environments/environment';
import { EntityApiBaseService } from "@app/services/api/base-core-api.service";
import { GetAssetByIdParams } from "@src/app/models/assets.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CoreAssetApiService extends EntityApiBaseService {
  private ASSET_RESOURCE = 'assets';
  private DRIVER_PROFILE_RESOURCE = 'driverprofiles';
  private DRIVER_ASSETS_RESOURCE = 'driverassets';

  getAssetById(assetId: string, params?: GetAssetByIdParams) {
    const url = `${environment.coreEntityApiBase.url}/${this.ASSET_RESOURCE}/${assetId}`;
    return this.getData(url, params);
  }

  getAssetDriver(assetId: string, params?: GetAssetByIdParams) {
    const url = `${environment.coreEntityApiBase.url}/${this.DRIVER_ASSETS_RESOURCE}?assetId=${assetId}&status=ACTIVE`;
    return this.getData(url, params).pipe(
      map((res: any) => res?.body?.length ? res.body[0] : res.body)
    );
  }

  getDriverProfile(driverProfileId: string, params?: GetAssetByIdParams) {
    const url = `${environment.coreEntityApiBase.url}/${this.DRIVER_PROFILE_RESOURCE}/${driverProfileId}`;
    return this.getData(url, params).pipe(
      map((res: any) => res?.body?.length ? res.body[0] : res.body)
    );
  }
}
