export const environment = {
  name: 'production-na',
  region: 'NA',
  production: true,
  appUrl: 'https://asset-profile.production.zonarsystems.net/',

  apiBase: {
    nonProdUrl: 'https://api.zonarsystems.net',
    url: 'https://api.zonarsystems.net'
  },

  coreEntityApiBase: {
    url: 'https://api.zonarsystems.net/core/v1'
  },
  userPreferenceApiBase: {
    url: 'https://api.zonarsystems.net/alert/v1'
  },
  // TODO: Setup your application's authentication variables: see
  // https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'production',

  auth: {
    clientID: 'wVBJv2SBcS8YZms2XECqGwWPmzHbEsf7',
    domain: 'zonar-login.auth0.com',
    audience: 'https://api.zonarsystems.net',
    // These come from the Core APIs as your application is built.
    applicationId: '0e133345-6f8f-4134-9236-1de0e2fb8371',
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/',
    key: '47e63a49-4477-47c2-4ea6-0f032e1785d5'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  //TODO: Setup your application's datadog app
  datadog: {
    applicationId: '<DATADOG_APPLICATION_ID>',
    clientToken: '<DATADOG_CLIENT_TOKEN>',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'prod'
  },

  i18n: {
    supportedLanguages: [
      'en',
      'en-US',
      'de-DE',
      'en-GB',
      'it',
      'fr-FR',
      'de',
      'es-ES'
    ],
    defaultLanguage: 'en-US'
  }
};
