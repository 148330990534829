<div class="flex flex-col h-full">
  <zui-sidenav
    [sidenavParams]="sidenavParams"
    [header]="sidenavHeader"
    (emitSelectedCompany)="switchCompany($event)">
    <div class="sidenav-container flex flex-col h-full">
      <zui-sidenav-header
        class="header-bar-sidenav"
        (menuToggled)="onSideNavMobileMenuButtonToggled($event)">
      </zui-sidenav-header>
      <div class="flex flex-col h-full justify-between overflow-x-hidden">
        <div class="content-container flex">
          <router-outlet></router-outlet>
        </div>
        <zui-footer-component></zui-footer-component>
      </div>
    </div>
  </zui-sidenav>
</div>
