import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AssetsDataStoreActions from './assets-data-store.actions';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { CoreAssetApiService } from '@src/app/services/api/core-asset-api.service';

/* actions */


@Injectable()
export class AssetsDataStoreEffects {

  constructor(
    private actions$: Actions,
    private coreAssetApiService: CoreAssetApiService
  ) { }


  getAssetById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.getAssetById),
      mergeMap(action =>
        this.coreAssetApiService.getAssetById(action.payload.assetId).pipe(
          map(res => res.body),
          switchMap(asset => {
            if (!asset?.id) {
              return of(asset);
            }
            return this.coreAssetApiService.getAssetDriver(asset.id).pipe(
              switchMap(assetDriver => {
                if (!assetDriver?.driverProfileId) {
                  return of(asset);
                }
                return this.coreAssetApiService.getDriverProfile(assetDriver.driverProfileId).pipe(
                  map(driverProfile => ({ ...asset, driverProfile }))
                );
              }),
              catchError(() => of(asset))
            );
          }),
          map(asset => AssetsDataStoreActions.getAssetByIdSuccessfully({ payload: { asset } })),
          catchError(error => of(AssetsDataStoreActions.getAssetByIdFailure({ payload: { error } })))
        )
      )
    )
  );

}
