import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { PermissionsService } from '@zonar-ui/auth';
import { Observable, combineLatest, of } from 'rxjs';
import { getAssetById } from '../data-stores/assets/assets-data-store.actions';
import { selectAsset, selectError } from '../data-stores/assets/assets-data-store.selects';
import { catchError, filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssetViewGuard implements CanActivate {

  constructor(
    private permissionService: PermissionsService,
    private store: Store<any>,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const assetId = route.params.id;
    this.store.dispatch(getAssetById({ payload: { assetId } }));
    return combineLatest([
      this.permissionService.getDivisionMap(),
      this.store.select(selectAsset),
      this.store.select(selectError)
    ]).pipe(
      filter(([divisionMap, asset, error]) => Boolean(divisionMap) && (Boolean(asset) || Boolean(error))),
      switchMap(([divisionMap, asset, error]) => {
        if (error) {
          console.log('Asset fetching error: ', error);
          return of(this.router.parseUrl('asset-not-found'));
        } else if (divisionMap && asset) {
          const existedDivision = asset.divisions?.find(divId => divisionMap[divId]);
          if (existedDivision || !Object.keys(divisionMap).length) {
            return of(true);
          } else {
            return of(this.router.parseUrl('asset-not-found'));
          }
        }
      }),
      catchError(() => {
        return of(this.router.parseUrl('asset-not-found'));
      })
    );
  }
}
