/* angular */
import { createAction, props } from '@ngrx/store';
import { IAsset } from '@src/app/models/assets.model';
/* action types */
export enum ActionTypes {

    GetAssetById = '[ASSETS] GetAssetById',
    GetAssetsSuccessfully = '[ASSETS] GetAssetsSuccessfully',
    GetAssetsFailure = '[ASSETS] GetAssetsFailure',
}

export const getAssetById = createAction(ActionTypes.GetAssetById,
    props<{ payload: { assetId: string} }>());
export const getAssetByIdSuccessfully = createAction(ActionTypes.GetAssetsSuccessfully,
    props<{ payload: { asset: IAsset } }>());
export const getAssetByIdFailure = createAction(ActionTypes.GetAssetsFailure,
    props<{ payload: { error: any } }>());