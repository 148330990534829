import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

/* actions */
import * as AppActions from './app-data-store.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CoreDivisionApiService } from '@src/app/services/api/core-division-api.service';

@Injectable()
export class AppDataStoreEffects {
  constructor(
    private actions$: Actions,
    private coreDivisionApiService: CoreDivisionApiService
  ) { }

  getDivisionById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.getDivisionById),
      mergeMap((action) => {
        const { divisionId } = action.payload;
        return this.coreDivisionApiService.getDivisionById(divisionId).pipe(
          map(res => res.body),
          mergeMap(division => {
            return [AppActions.getDivisionByIdSuccessfully({
              payload: { division }
            })];
          }),
          catchError(error => {
            return [AppActions.getDivisionByIdFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );
}
