/* Angular */
import { createAction, props } from '@ngrx/store';

/* action types */
export enum ActionTypes {
  InitApp = '[APP] InitApp',
  SetSelectedCompanyId = '[APP] SetSelectedCompanyId',
  GetDivisionById = '[APP] GetDivisionById',
  GetDivisionByIdSuccessfully = '[APP] GetDivisionByIdSuccessfully',
  GetDivisionByIdFailure = '[APP] GetDivisionByIdFailure',
}

/* actions */
export const initApp = createAction(ActionTypes.InitApp);

export const setSelectedCompanyId = createAction(ActionTypes.SetSelectedCompanyId,
  props<{ payload: { selectedCompanyId: string } }>());

export const getDivisionById = createAction(ActionTypes.GetDivisionById,
  props<{ payload: { divisionId: string } }>());

export const getDivisionByIdSuccessfully = createAction(ActionTypes.GetDivisionByIdSuccessfully,
  props<{ payload: { division: any } }>());

export const getDivisionByIdFailure = createAction(ActionTypes.GetDivisionByIdFailure,
  props<{ payload: { error: any } }>());
