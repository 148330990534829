import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EntityApiBaseService {
  constructor(
    private http: HttpClient
  ) { }

  protected getData(url: string, params?: any, responseType: any = { observe: 'response' }): Observable<any> {
    if (params) {
      const httpParams: HttpParams = new HttpParams({ fromObject: params as any });
      const options = Object.assign({ params: httpParams }, responseType);
      return this.http.get(url, options);
    }
    return this.http.get(url, responseType);
  }
}
